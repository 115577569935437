import { Block } from "@/blocks/base";
import nextArrow from "@/svg/icons/newQuoteNextArrow.svg";
import prevArrow from "@/svg/icons/newQuotePrevArrow.svg";
import prevArrowMobile from "@/svg/icons/arrow_backward_mobile.svg";
import nextArrowMobile from "@/svg/icons/arrow_forward_mobile.svg";
import ProgressCircle from "@/svg/icons/progress_circle";
import star from "@/svg/icons/star.svg";
import trustPilotLogo from "@/svg/icons/trustPilotLogo.svg";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import React, { useCallback, useRef } from "react";
import {
  accentColor,
  iconTrustPilotStyle,
  newQuoteAuthorBlock,
  newQuoteAuthorImgWrapperBlock,
  newQuoteAuthorNameStyle,
  newQuoteAuthorPositionStyle,
  newQuoteAuthorTextBlock,
  newQuoteContainerStyle,
  newQuoteHighlightedTextStyle,
  newQuoteMobileSliderNavStyle,
  newQuoteSlideDetailsStyle,
  newQuoteSlideQuoteTextStyle,
  newQuoteSliderContainerStyle,
  newQuoteSliderMobileNavButtonsStyle,
  newQuoteSliderNavButtonsStyle,
  newQuoteSliderWrapperStyle,
  newQuoteSlideStyle,
  newQuoteSlideTrustPilotStarsStyle,
  newQuoteSlideTrustPilotStTextStyle,
  newQuoteSlideTrustPilotStyle,
  newQuoteSlideTrustPilotTextStyle,
  newQuoteTrafficBlock,
  newQuoteTrafficImgWrapperBlock,
  newQuoteTrafficTextBlock,
  newQuoteTrafficTextCapacityStyle,
  newQuoteTrafficTextSinceStyle,
  newQuoteTrafficTextTitleStyle,
  starRatingContainerStyle,
} from "@/blocks/shared/NewQuote/newQuote.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { RemoteImage } from "@/uikit/remote-image";
import { BlockContainer } from "@/blocks/base/block-container";

const NewQuoteBlock = Block("blockNewQuote", (props) => {
  const { settings, quotes } = props;

  const sliderRef = useRef<SwiperRef>(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const IconPrev = prevArrow;
  const IconNext = nextArrow;
  const IconNextMobile = nextArrowMobile;
  const IconPrevMobile = prevArrowMobile;
  const IconStar = star;
  const IconTrustPilotLogo = trustPilotLogo;

  const quoteText = (quote) =>
    quote.body.map(({ body, isHighLighted }, index) => {
      if (isHighLighted) {
        return (
          <span key={body} className={newQuoteHighlightedTextStyle}>
            {index === quote.body.length - 2 && quote.body[quote.body.length - 1]?.body.length < 3
              ? " " + body
              : " " + body + " "}
          </span>
        );
      } else {
        return body;
      }
    });

  const haveMoreThanOneQuote = quotes.length > 1;

  return (
    <BlockContainer defaultTheme="light" settings={settings} className={newQuoteContainerStyle}>
      <div className={newQuoteSliderWrapperStyle}>
        {haveMoreThanOneQuote && (
          <button onClick={handlePrev} className={newQuoteSliderNavButtonsStyle}>
            <IconPrev />
          </button>
        )}

        <Swiper
          ref={sliderRef}
          grabCursor={true}
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          modules={[Navigation]}
          className={newQuoteSliderContainerStyle}
        >
          {quotes.map((quote, index) => (
            <SwiperSlide key={index + "quoteKey"}>
              <div className={newQuoteSlideStyle}>
                <div className={newQuoteSlideTrustPilotStyle}>
                  <div className={newQuoteSlideTrustPilotStarsStyle}>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <div key={index} className={starRatingContainerStyle}>
                        <IconStar />
                      </div>
                    ))}
                  </div>
                  <div className={newQuoteSlideTrustPilotTextStyle}>
                    <a href={quote.link}>
                      <span className={newQuoteSlideTrustPilotStTextStyle}> See our reviews on </span>
                      <IconTrustPilotLogo className={iconTrustPilotStyle} />
                    </a>
                  </div>
                </div>
                <div className={newQuoteSlideQuoteTextStyle}>“{quoteText(quote)}”</div>
                <div className={newQuoteSlideDetailsStyle}>
                  <div className={newQuoteAuthorBlock}>
                    <div className={newQuoteAuthorImgWrapperBlock}>
                      <RemoteImage image={quote.author.avatar} />
                    </div>
                    <div className={newQuoteAuthorTextBlock}>
                      <div className={newQuoteAuthorNameStyle}>{quote.author.name}</div>
                      <div className={newQuoteAuthorPositionStyle}>{quote.author.position}</div>
                    </div>
                  </div>
                  <div className={newQuoteTrafficBlock}>
                    <div className={newQuoteTrafficImgWrapperBlock}>
                      <ProgressCircle color={accentColor} progress={quote.traffic.currentCapacity} />
                    </div>
                    <div className={newQuoteTrafficTextBlock}>
                      <div className={newQuoteTrafficTextTitleStyle}>{quote.traffic.title}</div>
                      <div>
                        <span className={newQuoteTrafficTextCapacityStyle}>{quote.traffic.capacity}</span>
                        <span className={newQuoteTrafficTextSinceStyle}>{quote.traffic.since}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {haveMoreThanOneQuote && (
          <button onClick={handleNext} className={newQuoteSliderNavButtonsStyle}>
            <IconNext />
          </button>
        )}
      </div>
      {haveMoreThanOneQuote && (
        <div className={newQuoteMobileSliderNavStyle}>
          <button onClick={handlePrev} className={newQuoteSliderMobileNavButtonsStyle}>
            <IconPrevMobile />
          </button>
          <button onClick={handleNext} className={newQuoteSliderMobileNavButtonsStyle}>
            <IconNextMobile />
          </button>
        </div>
      )}
    </BlockContainer>
  );
});

export default NewQuoteBlock;
