"use client";
import { getResolvedImage } from "@/core/sanityAPI/types";
import type { SanityImageObject } from "@sanity/image-url/lib/types/types";
import Image, { ImageProps } from "next/image";
import React, { type CSSProperties } from "react";
import { SvgInline } from "../SVGUrl";

interface Sizes {
  mobile?: string;
  tablet?: string;
  desktop?: string;
}

const sizeQueries: Sizes = {
  mobile: "(max-width: 768px)",
  tablet: "(max-width: 1200px)",
  desktop: "",
};

interface ApsImageProps {
  priority?: boolean;
  quality?: ImageProps["quality"];
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
  image: SanityImageObject;
  sizes?: Sizes;
  fill?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
}

export const parseSizes = (sizes: Sizes) => {
  return Object.keys(sizes)
    .map((key) => {
      return `${sizeQueries[key]} ${sizes[key]}`;
    })
    .join(",");
};

const invalidImage = `
  <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" fill="rgba(255,0,0,0.5)"/>
    <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="black" font-size="calc(5vmin + 5vh)" style="max-width:50%; max-height:90%">
      Invalid
    </text>
  </svg>
`;

export const RemoteImage = React.memo<ApsImageProps>((props) => {
  const sizes = props.sizes ? parseSizes(props.sizes) : undefined;

  const noFill = !props.fill;

  const resolvedImage = getResolvedImage(props.image);

  if (!resolvedImage) {
    return <SvgInline svg={invalidImage} />;
  }

  const width = props.width || resolvedImage.width;
  const height = props.height || resolvedImage.height;

  return (
    <Image
      src={resolvedImage.src}
      sizes={sizes}
      width={noFill ? width : undefined}
      height={noFill ? height : undefined}
      priority={props.priority}
      className={props.className}
      style={props.style ? props.style : noFill ? { maxWidth: "100%", width: "100%", height: "auto" } : undefined}
      alt={props.alt || ""}
      fill={props.fill}
      quality={props.quality}
      onClick={props.onClick}
    />
  );
});

RemoteImage.displayName = "ApsImage";
