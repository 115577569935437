import React from "react";
import { clsx } from "../utils";
import { containerStyle } from "./styles.css";

interface Props {
  className: string;
  src: string;
  repeat?: boolean;
}

const BackgroundImage = ({ className, src, repeat }: Props) => {
  return (
    <div
      className={clsx(containerStyle, className)}
      style={{ backgroundImage: `url(${src})`, backgroundRepeat: !repeat ? "repeat-x" : "no-repeat" }}
    ></div>
  );
};

export default BackgroundImage;
