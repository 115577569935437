import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import { duckStyle, titleStyle } from "./styles.css";
import { Svg } from "@/uikit/Svg";
import { Typography } from "@/uikit/Typography";

import React from "react";

export const TitleWithImageBlock = Block("blockWithTitleAndImage", (props) => {
  return (
    <BlockContainer defaultTheme="silver" settings={props.settings}>
      <Typography.subtitle className={titleStyle}>{props.title}</Typography.subtitle>

      <Svg className={duckStyle} asset={props.image} />
    </BlockContainer>
  );
});
