"use client";

import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import { Typography } from "@/uikit/Typography";
import React from "react";

import { titleStyle } from "./pricingTitleContainer.css";

import { HeaderPlansSection } from "@/blocks/pricing/PlansSection";

const PricingTitleContainer = Block("pricingTitleContainer", (props) => {
  return (
    <BlockContainer settings={props.settings} defaultTheme="light" layout="centeredColumn">
      <Typography.title className={titleStyle}>{props.title}</Typography.title>
      <HeaderPlansSection plans={props.plans} />
    </BlockContainer>
  );
});

export default PricingTitleContainer;
