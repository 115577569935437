import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import React from "react";
import {
  videoReviewsContainerStyle,
  videoReviewsContainerWrapperStyle,
  videoReviewsTitleStyle,
  videoReviewsVideosScrollWrapperStyle,
} from "@/blocks/VideoReviews/videoReviews.css";
import { resolveRef } from "@/core/sanityAPI/client-resolvers";
import { VideoReviewVideoContainer } from "@/blocks/VideoReviews/VideoReviewVideoContainer/VideoReviewVideoContainer";

const VideoReview = Block("blockVideoReview", (props) => {
  const { settings, video } = props;
  const videoUrl = video.map((item) => {
    return resolveRef(item.videoFile.asset).url;
  });

  return (
    <BlockContainer className={videoReviewsContainerWrapperStyle} defaultTheme={undefined} settings={settings}>
      <div className={videoReviewsContainerStyle}>
        <div className={videoReviewsTitleStyle}> What Our Users Say </div>
        <div className={videoReviewsVideosScrollWrapperStyle}>
          {video.map((item, index) => {
            return (
              <div key={index}>
                <VideoReviewVideoContainer
                  placeholder={item.videoPreviewImage}
                  videoUrl={videoUrl[index]}
                  authorName={item.authorName}
                  authorPosition={item.authorPosition}
                />
              </div>
            );
          })}
        </div>
      </div>
    </BlockContainer>
  );
});

export default VideoReview;
