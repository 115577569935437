import React from "react";

import { BlockMapping } from "@/blocks/base";
import { TitleWithImageBlock } from "@/blocks/titleWithImage";
import dynamic from "next/dynamic";

import MainScreen from "./MainScreen";
import PricingTitleContainer from "./PricingTitleContainer";
import NewQuoteBlock from "@/blocks/shared/NewQuote";
import VideoReviews from "@/blocks/VideoReviews";

const Companies = dynamic(() => import("./Companies"));
const ContactSection = dynamic(() => import("./ContactSection"));
const Benefits = dynamic(() => import("./Benefits"));

const Scope = dynamic(() => import("./Scope"));
const Contact = dynamic(() => import("./Contact"));
const NeedMoreSection = dynamic(() => import("./NeedMore"));
const Trial = dynamic(() => import("./Trial"));
const Docs = dynamic(() => import("./Docs"));
const Download = dynamic(() => import("./Download"));
const About = dynamic(() => import("./About"));
const NotFound = dynamic(() => import("./NotFound"));
const Debug = dynamic(() => import("./Debug"));
const Tabs = dynamic(() => import("./Tabs"));

const DownloadTabs = dynamic(() => import("./downloadsTabs"));
const Quote = dynamic(() => import("./Quote"));
const VideoBlock = dynamic(() => import("./shared/videoBlock"));
const NewsAndUpdatesBlock = dynamic(() => import("./NewsAndUpdates"));
const InfoBoxBlock = dynamic(() => import("./InfoBox"));
const Feature = dynamic(() => import("./FeatureSection"));
const MonitorHeader = dynamic(() => import("./MonitorHeader"));
const BlogAboutUs = dynamic(() => import("@/components/blog/AboutUs"));
const PlansTable = dynamic(() => import("./PlansTable"));

const BuilderBody = dynamic(() => import("./builder/Body"));
const BuilderHImage = dynamic(() => import("./builder/HImage"));
const BuilderCollection = dynamic(() => import("./builder/Collection"));
const BuilderTabs = dynamic(() => import("./builder/Tabs"));
const BuilderFaq = dynamic(() => import("./builder/Faq"));
const BuilderRef = dynamic(() => import("./builder/Ref"));

const TrustedBy = dynamic(() => import("./shared/TrustedBy"));

const Demo = dynamic(() => import("./shared/Demo"));
const MainScreenBlockShared = dynamic(() => import("./shared/MainScreenShared"));

const NotImplemented = () => {
  return <h1>Block Not Implemented</h1>;
};

const mapping: BlockMapping = {
  blockBody: { component: BuilderBody },
  blockHImage: { component: BuilderHImage },
  blockCollection: { component: BuilderCollection },
  blockTabs: { component: BuilderTabs },
  blockFaq: { component: BuilderFaq },
  blockRef: { component: BuilderRef },

  blockDemo: { component: Demo },

  blockTrustedBy: { component: TrustedBy },

  pricingTitleContainer: { component: PricingTitleContainer },
  trial: { component: Trial },
  pricingContactSection: { component: ContactSection },
  needMore: { component: NeedMoreSection },
  mainScreen: { component: MainScreen },
  mainScreenHeading: { component: MainScreen as any },
  benefits: { component: Benefits },

  scope: { component: Scope },
  companies: { component: Companies },
  contactUsWidget: { component: Contact },
  download: { component: Download },
  docs: { component: Docs },
  about: { component: About },
  notFound: { component: NotFound },
  blockWithTitleAndImage: { component: TitleWithImageBlock },
  feature: { component: Feature },
  debug: { component: Debug },
  tabs: { component: Tabs },
  downloadsTabs: { component: DownloadTabs },
  quote: { component: Quote },
  blockVideo: { component: VideoBlock },
  blockNewQuote: { component: NewQuoteBlock },
  blockVideoReview: { component: VideoReviews },
  newsAndUpdates: { component: NewsAndUpdatesBlock },
  infoBoxBlock: { component: InfoBoxBlock },
  planCharacteristicTable: { component: NotImplemented },
  monitorHeader: { component: MonitorHeader },
  blogAboutUs: { component: BlogAboutUs as any },
  plansTable: { component: PlansTable },

  // TESTS:
  mainScreenBlockShared: { component: MainScreenBlockShared },
};

export default mapping;

interface BlockProps {
  _type: string;
}

export function renderBlock(block: BlockProps, index = 0, mappings = mapping) {
  if (!block) {
    return null;
  }

  const blockConfig = mappings[block._type];

  if (!blockConfig) {
    return null;
  }

  const Component = blockConfig.component as React.FC<any>;

  return <Component key={index} {...block} />;
}
