import { Typography } from "@/uikit/Typography";
import { FlexContainer } from "@/uikit/container";
import React from "react";
import PropTypes from "prop-types";

import Section from "@/uikit/Section";
import { PlanCard } from "./PlanCard";
import { PlanItem } from "./PlanItem";

import { PricingPlansSchema, PricingPlanSchema } from "src/schema";

import {
  plansSectionContainerStyle,
  plansSectionContentStyle,
  titleStyle,
  sectionTitleStyle,
  plansContainerStyle,
  desktopHeaderContainerStyle,
  plansSectionWrapperStyle,
  cardsWrapperStyle,
} from "./plansSection.css";

const PlansDesktopHeader = (props) => (
  <div className={desktopHeaderContainerStyle}>
    <div>{props.children}</div>
    <div>Traffic</div>
    <div>Seats</div>
    <div>Price</div>
  </div>
);

PlansDesktopHeader.propTypes = {
  children: PropTypes.node,
};

function zip<T>(...arrays: T[][]) {
  const length = Math.min(...arrays.map((array) => array.length));
  return Array.from({ length }, (_, i) => arrays.map((array) => array[i]));
}

// FIXME: rewrite this component to functional
//
export default class PlansSection extends React.PureComponent<PricingPlansSchema> {
  override render() {
    const monthPlans = this.props.plans.filter((plan) => plan.period === "monthly");
    const annualPlans = this.props.plans.filter((plan) => plan.period === "annually");

    if (monthPlans.length !== annualPlans.length) {
      throw new Error("shit've just hit fan");
    }

    const plans = zip(monthPlans, annualPlans)
      .map((pair) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const plan = pair[0]!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        plan.annualPrice = pair[1]!.price;
        return plan;
      })
      .sort((a, b) => a.price - b.price);

    const individualPlans = this.getPlansArray((plan) => plan.accountType === "user", plans);

    const teamPlans = this.getPlansArray((plan) => plan.accountType === "organization", plans);

    return (
      <Section className={`${plansSectionContainerStyle}`} contentStyle={plansSectionContentStyle}>
        <Typography.subtitle className={titleStyle}>All availiable plans</Typography.subtitle>
        <Typography.subtitle className={sectionTitleStyle}>Personal Plans</Typography.subtitle>
        <div className={plansContainerStyle}>
          <PlansDesktopHeader>Personal Plans</PlansDesktopHeader>
          {individualPlans}
        </div>
        <Typography.subtitle className={sectionTitleStyle}>Team Plans</Typography.subtitle>
        <div className={plansContainerStyle}>
          <PlansDesktopHeader>Team Plans</PlansDesktopHeader>
          {teamPlans}
        </div>
      </Section>
    );
  }

  getPlansArray = (predicate: (plan: PricingPlanSchema) => void, plans: PricingPlanSchema[]) => {
    return plans
      .filter((plan) => predicate(plan))
      .map((plan) => {
        return <PlanItem key={plan.id} plan={plan} />;
      });
  };

  handleIndividualPeriodChange = (period) => {
    this.setState({ individualPeriod: period });
  };

  handleTeamPeriodChange = (period) => {
    this.setState({ teamPeriod: period });
  };
}

interface HeaderPlansSectionProps {
  plans: PricingPlanSchema[];
}

export const HeaderPlansSection = React.memo((props: HeaderPlansSectionProps) => {
  const cards = props.plans.map((plan) => (
    <PlanCard key={plan.id} plan={plan} cardVariant={plan.highlighted ? "highlighted" : "default"} />
  ));
  return (
    <div className={plansSectionWrapperStyle}>
      <FlexContainer
        className={cardsWrapperStyle}
        justifyContent="center"
        alignItems="stretch"
        gap="medium"
        wrap="wrap"
      >
        {cards}
      </FlexContainer>
    </div>
  );
});

HeaderPlansSection.displayName = "HeaderPlansSection";
