import "../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyles%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA23S227CMAwA0He%2BwlKeQbk5Tc0Tleh%2F9JLuBoNdgU3798mdJjlR46cepXbsZHM6nG6Xs4bvFcB6%2FfdlCJSNNjqzFWoJ1DQvqY5ADYlDqidQ%2B2bftHupSKB84xu0UgPnrYOpeqkVgWqbaGqUGrmaHvSQaU2g9LykdpzXT3HK8vYEytWu85XUgdU79FnHI4GqXOxrLzXxdIw1tpY6LU7njjVxSL3n3nbtrm2lPizO4XFxDk9zNeyrrIsDgRoHDqlHApUwYcpO9swZkEPq6V87qWeeehjCmE3nhUDhiClk1V65WsCuyva%2BzZPsEEep74tz%2BOC7cM757DY%2FF9%2FOZfGdXQn0%2BSrlRhBz%2BSIwNiejCUwozBDYIpmxBNYX5ghsUcJ4Al%2F%2BiwRY1g0ERpcbqxkzinyWnGqCWJbtuLVQHrAn0BuXjtvVzy9J5bov9QMAAA%3D%3D%22%7D"
import "../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fuikit%2FCommon%2Fcommon.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xeGVyYjlzMCB7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGp1c3RpZnktY29udGVudDogY2VudGVyOwogIGJhY2tncm91bmQtY29sb3I6IHZhcigtLW9sb3l3cDYpOwogIGJvcmRlci1yYWRpdXM6IDRweDsKICBmb250LXNpemU6IDE2cHg7CiAgZm9udC13ZWlnaHQ6IDcwMDsKICBib3gtc2hhZG93OiAwIDVweCAxNXB4IDAgcmdiYSgyNDksIDk3LCAxMjMsIDAuMik7CiAgY3Vyc29yOiBwb2ludGVyOwp9Ci5fMXhlcmI5czA6aG92ZXIgewogIGJhY2tncm91bmQtY29sb3I6ICNlNjVhNzE7Cn0KLl8xeGVyYjlzMSB7CiAgY29sb3I6IHZhcigtLW9sb3l3cDYpOwogIGJhY2tncm91bmQtY29sb3I6IHZhcigtLW9sb3l3cDIpOwogIGJveC1zaGFkb3c6IDAgMnB4IDRweCAwIHJnYmEoMCwgMCwgMCwgMC4wNSk7Cn0KLl8xeGVyYjlzMTpob3ZlciB7CiAgYmFja2dyb3VuZC1jb2xvcjogI2ViZWJlYjsKfQouXzF4ZXJiOXMyIHsKICBsaW5lLWhlaWdodDogMS4zMzsKICBjb2xvcjogdmFyKC0tb2xveXdwMyk7Cn0KLl8xeGVyYjlzMyB7CiAgdGV4dC1hbGlnbjogY2VudGVyOwp9Ci5fMXhlcmI5czQgewogIHdpZHRoOiAxMDAlOwp9Ci5fMXhlcmI5czUgewogIHotaW5kZXg6IDEwOwogIHBhZGRpbmc6IDAgMjBweDsKfQouXzF4ZXJiOXM2IHsKICBmb250LXdlaWdodDogNzAwOwp9CkBtZWRpYSAobWluLXdpZHRoOiA2MDBweCkgewogIC5fMXhlcmI5czAgewogICAgZm9udC1zaXplOiAxOHB4OwogIH0KfQ%3D%3D%22%7D"
import "../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2FMainScreen%2FmainScreen.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xZzFjdnlmMCB7CiAgcG9zaXRpb246IHJlbGF0aXZlOwp9Ci5fMWcxY3Z5ZjEgewogIHRleHQtYWxpZ246IGNlbnRlcjsKICBmb250LXNpemU6IDU0cHg7CiAgbWF4LXdpZHRoOiA3MDBweDsKICBsaW5lLWhlaWdodDogNjRweDsKfQouXzFnMWN2eWYyIHsKICBtYXgtd2lkdGg6IDkwdnc7CiAgbWFyZ2luOiAwIGF1dG87Cn0KLl8xZzFjdnlmMyB7CiAgZm9udC1zaXplOiAyMHB4OwogIG1heC13aWR0aDogNjMwcHg7CiAgbGluZS1oZWlnaHQ6IDMycHg7CiAgdGV4dC1hbGlnbjogY2VudGVyOwp9Ci5fMWcxY3Z5ZjQgewogIHdpbGwtY2hhbmdlOiBvcGFjaXR5Owp9Ci5fMWcxY3Z5ZjYgewogIHBvc2l0aW9uOiByZWxhdGl2ZTsKICBwYWRkaW5nOiAxMDBweCAwOwogIGhlaWdodDogNDgwcHg7Cn0KLl8xZzFjdnlmNyB7CiAgZ2FwOiAyMHB4Owp9Ci5fMWcxY3Z5ZjggewogIG9iamVjdC1maXQ6IGNvdmVyOwp9CkBtZWRpYSAobWF4LXdpZHRoOiA3NjdweCkgewogIC5fMWcxY3Z5ZjEgewogICAgZm9udC1zaXplOiA0MHB4OwogICAgbGluZS1oZWlnaHQ6IDQ4cHg7CiAgICB3aWR0aDogMTAwJTsKICB9CiAgLl8xZzFjdnlmNiB7CiAgICBoZWlnaHQ6IGF1dG87CiAgICBwYWRkaW5nOiAxMDBweCAwIDUwcHg7CiAgfQogIC5fMWcxY3Z5ZjggewogICAgZGlzcGxheTogbm9uZTsKICB9Cn0KQG1lZGlhIChtYXgtd2lkdGg6IDQwMHB4KSB7CiAgLl8xZzFjdnlmNCB7CiAgICB3aWR0aDogMTAwJTsKICB9CiAgLl8xZzFjdnlmNSB7CiAgICB3aWR0aDogMTAwJTsKICB9Cn0%3D%22%7D"
export var actionsWrapperStyle = '_1g1cvyf5';
export var bgImgStyle = '_1g1cvyf8';
export var demoWrapperStyle = '_1g1cvyf2';
export var mainContentStyle = '_1g1cvyf7 _1xerb9s5';
export var mainWrapperStyle = '_1g1cvyf6';
export var platformsWrapperStyle = '_1g1cvyf4 _1xerb9s5';
export var rootStyle = '_1g1cvyf0';
export var subtitleStyle = '_1g1cvyf3';
export var titleStyle = '_1g1cvyf1';