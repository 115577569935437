import React from "react";

import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import { renderActions } from "@/blocks/schema-mappers";
import { FlexContainer } from "@/uikit/container";

import { Typography } from "@/uikit/Typography";
import Platforms from "src/components/Platforms";

import {
  rootStyle,
  titleStyle,
  subtitleStyle,
  mainWrapperStyle,
  bgImgStyle,
  mainContentStyle,
  platformsWrapperStyle,
  actionsWrapperStyle,
} from "./mainScreen.css";
import { resolveRef } from "@/core/sanityAPI/client-resolvers";
import BackgroundImage from "@/uikit/BackgroundImage";

const MainScreen = Block("mainScreen", (props) => {
  return (
    <div className={rootStyle}>
      <BackgroundImage className={bgImgStyle} src="/assets/bg_v2.png" />
      <BlockContainer settings={props.settings} width="full" defaultTheme="light" blockPaddingBottom="none">
        <FlexContainer className={mainWrapperStyle} direction="column" gap="l">
          <FlexContainer direction="column" className={mainContentStyle}>
            <Typography.title className={titleStyle}>{props.title}</Typography.title>
            <Typography className={subtitleStyle} settings={{ tag: "p" }}>
              {props.subtitle}
            </Typography>
            {props.actions && <div className={actionsWrapperStyle}>{renderActions(props.actions)}</div>}
          </FlexContainer>
          <div className={platformsWrapperStyle}>
            <Platforms {...resolveRef(props.platformsRef)} />
          </div>
        </FlexContainer>
      </BlockContainer>
    </div>
  );
});

export default MainScreen;
