import React from "react";

interface Props {
  color?: string;
  progress: number;
}

const ProgressCircle = ({ color, progress }: Props) => {
  const radius = 20.5;
  const strokeWidth = 8;
  const cirleCenter = (radius * 2 + strokeWidth) / 2;

  const generateDashArray = (progress: number) => {
    const circumference = Math.PI * 2 * radius;
    return `${progress * circumference} ${circumference - progress * circumference}`;
  };

  return (
    <svg width="49px" height="49px" viewBox="0 0 49 49">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <path
            fill="#4B4B52"
            d="M24.5,0 C38.0309764,0 49,10.9690236 49,24.5 C49,38.0309764 38.0309764,49 24.5,49 C10.9690236,49 0,38.0309764 0,24.5 C0,10.9690236 10.9690236,0 24.5,0 Z M24.5,8 C15.3873016,8 8,15.3873016 8,24.5 C8,33.6126984 15.3873016,41 24.5,41 C33.6126984,41 41,33.6126984 41,24.5 C41,15.3873016 33.6126984,8 24.5,8 Z"
          ></path>
          <circle
            id="progress"
            stroke={color || "currentColor"}
            fill="transparent"
            strokeWidth={strokeWidth}
            cx={cirleCenter}
            cy={cirleCenter}
            r={radius}
            strokeDasharray={generateDashArray(progress)}
            transform="rotate(-90) translate(-49)"
            strokeLinecap="round"
          ></circle>
        </g>
      </g>
    </svg>
  );
};

export default ProgressCircle;
