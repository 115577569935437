import React from "react";

import { PricingPlanSchema } from "src/schema";

import { planCardContainerStyle, planTitleStyle, featureRowStyle } from "./planItem.css";

type PlanItemProps = {
  plan: PricingPlanSchema & { annualPrice?: number };
};

export const PlanItem = ({ plan }: PlanItemProps) => {
  const price = (plan.price / 100).toString();
  const annualPrice = (plan.annualPrice / 100).toString();

  return (
    <div className={planCardContainerStyle}>
      <div className={planTitleStyle}>{plan.name}</div>
      <div className={featureRowStyle}>
        <div>Traffic</div>
        <div>{plan.trafficPrepaid} GB</div>
      </div>
      <div className={featureRowStyle}>
        <div>Seats</div>
        <div>{plan.seats}</div>
      </div>
      <div className={featureRowStyle}>
        <div>Price</div>
        <div>
          ${price} per month / ${annualPrice} per year
        </div>
      </div>
    </div>
  );
};
