import { FlexContainer } from "@/uikit/container";
import React from "react";

import { Typography } from "@/uikit/Typography";

import { PlatformsSchema } from "@/schema";

import { titleStyle, platformStyle, svgStyle } from "./platforms.css";

import AppleIcon from "@/svg/icons/apple.svg";
import AppleTvIcon from "@/svg/icons/apple-tv.svg";
import Android from "@/svg/icons/android.svg";
import Flutter from "@/svg/icons/flutter.svg";

const iconsMapping = {
  ios: AppleIcon,
  appleTv: AppleTvIcon,
  android: Android,
  flutter: Flutter,
};

const Platforms = ({ title = "We support", platforms }: PlatformsSchema) => {
  return (
    <FlexContainer gap="small">
      <Typography className={titleStyle} settings={{ tag: "span" }}>
        {title.toUpperCase()}
      </Typography>
      <FlexContainer gap="l">
        {platforms?.map(({ link, type }, index) => {
          const Icon = iconsMapping[type];

          if (!Icon) {
            return null;
          }

          return (
            <a className={platformStyle} aria-label={type} key={index} href={link} target="_blank" rel="noreferrer">
              <Icon className={svgStyle} />
            </a>
          );
        })}
      </FlexContainer>
    </FlexContainer>
  );
};

interface MonitorPlatformsProps {
  platforms: string[];
}

export const MonitorPlatforms = ({ platforms }: MonitorPlatformsProps) => {
  return (
    <FlexContainer gap="small">
      <Typography className={titleStyle} settings={{ tag: "span" }}>
        Supported on
      </Typography>
      <FlexContainer gap="l">
        {platforms.map((type, index) => {
          const Icon = iconsMapping[type];

          if (!Icon) {
            return null;
          }

          return (
            <span className={platformStyle} key={index}>
              <Icon className={svgStyle} />
            </span>
          );
        })}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Platforms;
