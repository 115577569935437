import React from "react";
import { humanizeDuration } from "@/core/utils";

import { PricingPlanSchema } from "src/schema";

import {
  cardContainerStyle,
  nameStyle,
  priceTextStyle,
  periodTextStyle,
  characteristicsWrapperStyle,
  characteristicBlockStyle,
  characteristicsValueStyle,
  characteristicsDescStyle,
  featureStyle,
  bottomPlanSectionStyle,
  priceWrapperStyle,
  btnStyle,
  descriptionStyle,
  featuresWrapperStyle,
  startFromTextStyle,
} from "./planCard.css";
import { Button } from "@/blocks/tracking";
import { Typography } from "@/uikit/Typography";
import { FlexContainer } from "@/uikit/container";

import CheckIcon from "@/svg/icons/check.svg";

type PlanCardProps = {
  plan: PricingPlanSchema;
  cardVariant: "highlighted" | "default";
};

export const PlanCard = ({ plan, cardVariant }: PlanCardProps) => {
  const price = (plan.price / 100).toFixed(2);
  return (
    <FlexContainer direction="column" justifyContent="between" className={cardContainerStyle({ variant: cardVariant })}>
      <div>
        <Typography className={nameStyle} settings={{ tag: "p" }}>
          {plan.name}
        </Typography>
        <Typography className={descriptionStyle}>{plan.description}</Typography>
        <FlexContainer justifyContent="around" className={characteristicsWrapperStyle}>
          <div className={characteristicBlockStyle}>
            <Typography className={characteristicsValueStyle} settings={{ tag: "p" }}>
              {plan.trafficPrepaid} GB
            </Typography>
            <Typography className={characteristicsDescStyle}>of traffic</Typography>
          </div>
          <div className={characteristicBlockStyle}>
            <Typography className={characteristicsValueStyle} settings={{ tag: "p" }}>
              {humanizeDuration(plan.sessionRetentionTime * 1000)}
            </Typography>
            <Typography className={characteristicsDescStyle}>of session history</Typography>
          </div>
        </FlexContainer>
        <FlexContainer direction="column" alignItems="start" className={featuresWrapperStyle}>
          {plan.features?.map((feature, i) => (
            <FlexContainer key={i}>
              <CheckIcon />
              <Typography className={featureStyle}>{feature}</Typography>
            </FlexContainer>
          ))}
        </FlexContainer>
      </div>
      <div className={bottomPlanSectionStyle}>
        <FlexContainer alignItems="center" gap="none" className={priceWrapperStyle}>
          {Number(plan.seats) > 1 ? <Typography className={startFromTextStyle}>Starting from</Typography> : ""}
          <Typography className={priceTextStyle}>${price}</Typography>
          <Typography className={periodTextStyle}>/mo</Typography>
        </FlexContainer>
        {plan.button && (
          <Button
            className={btnStyle}
            size="medium"
            fontWeight="bold"
            variant={cardVariant === "highlighted" ? "default" : "bordered"}
            eventName={plan.trackingEvent}
            to={plan.button.link}
          >
            {plan.button.text}
          </Button>
        )}
      </div>
    </FlexContainer>
  );
};
