import React, { useEffect, useRef, useState } from "react";
import type { SanityImageObject } from "@sanity/image-url/lib/types/types";
import { trackEvent } from "@/core/analytics";
import { getResolvedImage } from "@/core/sanityAPI/types";
import { RemoteImage } from "@/uikit/remote-image";
import PlayIcon from "@/svg/icons/play-video-icon.svg";
import { Loader } from "@/uikit/Loader";
import {
  videoReviewsLoaderStyle,
  videoReviewsPlayVideoButton,
  videoReviewsPlayVideoPosition,
  videoReviewsPlayVideoText,
  videoReviewsPlayVideoTitle,
  videoReviewsStyledAppContainer,
  videoReviewsVideoContainerUIStyle,
  videoReviewsVideoStyle,
} from "@/blocks/VideoReviews/VideoReviewVideoContainer/videoReviewsVideoContainer.css";

interface VideoConteinerProps {
  placeholder: SanityImageObject;
  videoUrl: string;
  authorName: string;
  authorPosition: string;
}

type State = "preview" | "loading" | "playing";
export const VideoReviewVideoContainer: React.FC<VideoConteinerProps> = ({
  placeholder,
  videoUrl,
  authorName,
  authorPosition,
}) => {
  const [state, setState] = useState<State>("preview");
  const [isVideoActive, setIsVideoActive] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlay = async () => {
    setState("loading");
    setIsVideoActive(true);
    trackEvent("playVideo");
  };

  useEffect(() => {
    if (videoRef && videoRef.current) {
      setState("playing");

      videoRef.current.play().catch((err) => {
        console.error(err.message);
        setState("preview");
      });
    }
  }, [isVideoActive]);

  const placeholderImage = getResolvedImage(placeholder);

  if (!placeholderImage) {
    return null;
  }

  const placeholderAspect = (placeholderImage.width || 8) / (placeholderImage.height || 5);
  const aspectRatioStyle = { aspectRatio: placeholderAspect };
  console.log(aspectRatioStyle);
  return (
    <div className={videoReviewsStyledAppContainer()} style={aspectRatioStyle}>
      {state === "preview" && (
        <>
          <RemoteImage
            fill
            image={placeholder}
            priority={true}
            quality={65}
            sizes={{
              mobile: "calc(100vw - 20px)",
              tablet: "calc(100vw - 20px)",
              desktop: "calc(100vw - 20px)",
            }}
          />
          <div className={videoReviewsVideoContainerUIStyle}>
            <div className={videoReviewsPlayVideoText}>
              <div className={videoReviewsPlayVideoTitle}>{authorName}</div>
              <div className={videoReviewsPlayVideoPosition}>{authorPosition}</div>
            </div>
            <button className={videoReviewsPlayVideoButton} aria-label="play video" onClick={handlePlay}>
              <PlayIcon />
            </button>
          </div>
        </>
      )}
      {state === "loading" && (
        <>
          <RemoteImage
            image={placeholder}
            priority={true}
            quality={65}
            sizes={{
              mobile: "calc(100vw - 20px)",
              tablet: "calc(100vw - 20px)",
              desktop: "calc(100vw - 20px)",
            }}
          />
          <Loader className={videoReviewsLoaderStyle} />
        </>
      )}
      {isVideoActive && (
        <video className={videoReviewsVideoStyle} ref={videoRef} src={videoUrl} muted controls playsInline />
      )}
    </div>
  );
};
