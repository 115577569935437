import { blockContent, BlockContent, blockRecipe, BlockRecipe } from "@/blocks/base/block.css";
import { BlockSettingsSchema } from "@/schema";
import { clsx } from "@/uikit/utils";
import React from "react";

interface BlockContainerExtraParams {
  defaultTheme: BlockSettingsSchema["theme"];
  className?: string;
  settings?: BlockSettingsSchema;
  id?: string;
}

type BlockContainerParams = BlockContainerExtraParams & BlockRecipe & BlockContent & React.PropsWithChildren;
export const BlockContainer = (props: BlockContainerParams) => {
  const { children, settings, blockPaddingTop, blockPaddingBottom, defaultTheme, layout, width, className, id } = props;

  const debug = process.env.NODE_ENV === "development";

  const finalTheme = settings?.theme || defaultTheme;

  const paddingTop = settings?.paddingTop || blockPaddingTop || "small";
  const paddingBottom = settings?.paddingBottom || blockPaddingBottom || "small";

  const sectionClassName = blockRecipe({
    theme: finalTheme,
    blockPaddingTop: paddingTop,
    blockPaddingBottom: paddingBottom,
    debug,
  });

  const contentClassName = blockContent({ layout, width });

  const combinedSectionClassName = clsx(sectionClassName, className);

  return (
    <section className={combinedSectionClassName} id={id}>
      <div className={contentClassName}>{children}</div>
    </section>
  );
};
