import React from "react";

import { rootStyle, spinnerStyle } from "./loader.css";

interface LoaderProps {
  className?: string;
}

export const Loader = ({ className }: LoaderProps) => (
  <div className={`${rootStyle} ${className}`}>
    <span className={spinnerStyle} />
  </div>
);
