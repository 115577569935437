import "../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyles%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA23S227CMAwA0He%2BwlKeQbk5Tc0Tleh%2F9JLuBoNdgU3798mdJjlR46cepXbsZHM6nG6Xs4bvFcB6%2FfdlCJSNNjqzFWoJ1DQvqY5ADYlDqidQ%2B2bftHupSKB84xu0UgPnrYOpeqkVgWqbaGqUGrmaHvSQaU2g9LykdpzXT3HK8vYEytWu85XUgdU79FnHI4GqXOxrLzXxdIw1tpY6LU7njjVxSL3n3nbtrm2lPizO4XFxDk9zNeyrrIsDgRoHDqlHApUwYcpO9swZkEPq6V87qWeeehjCmE3nhUDhiClk1V65WsCuyva%2BzZPsEEep74tz%2BOC7cM757DY%2FF9%2FOZfGdXQn0%2BSrlRhBz%2BSIwNiejCUwozBDYIpmxBNYX5ghsUcJ4Al%2F%2BiwRY1g0ERpcbqxkzinyWnGqCWJbtuLVQHrAn0BuXjtvVzy9J5bov9QMAAA%3D%3D%22%7D"
import "../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2FVideoReviews%2FVideoReviewVideoContainer%2FvideoReviewsVideoContainer.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA41UzY6jMAy%2B9ykirUZqD6kCLUM3vcxjzG0VwLTZhhglobSz6ruvEqAtFI3mErDjz3%2BfnfWfqBTtMYoZ%2BbcgJEeFhpOzMEtKUeG1rcvVfnFbrAe7KNjVaKWTqDkRmUXVONgvCPmiUhdw4ST2UobOYcUJ80IrC3fkJGLszYu1KAqpD5wwEm%2Fry%2BPwl4W0tRJXTkoFQeG%2FtJAG8i6mwdar%2FzbWyfJKc9QOtOPE1iIHmoFrAfQo6zhkXaJ21Mov4PdYQdWCPBwdJzvGRqjNFBUlM6jtBLUNqJciXrLNQTsw%2FkooedBUOqjss7rv2WbXBT328QY5Q1OAoUYUsrGcJF1nO23f9Uzkp4PBRhd0hlm52j8o%2F1WWpRedEXrgdgonbB1bAsICxcaNi%2BZHPIPphqgx1rusUQ6lfJ%2FIaTXxJXInzxCcfY80Y2QyGU4DSnhPM%2B2K4q6Lk8GspKa9KvndmwhbQ%2B6oEU4iJ9E6TUdB30PQiZ%2BBrPvA%2F2BhHNY9bwpKd2c0MFKiqXj3q4SDzyVN2FvgrxIXOgmOjVNSAyca9WztrJ%2Bgl95mSuSnUXXpj%2Fd9mn7Yio8KCinI8inL9D2tL6vgdvKqjN4FT9Dj8G5vz4i4RzxvZ78Yk%2F1MGXtBb2bQm5koSW%2F3NBbxbjsY3v4D%2Bf9IXD4FAAA%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var videoReviewsLoaderStyle = '_1fawh127';
export var videoReviewsPlayVideoButton = '_1fawh124';
export var videoReviewsPlayVideoPosition = '_1fawh123';
export var videoReviewsPlayVideoText = '_1fawh120';
export var videoReviewsPlayVideoTitle = '_1fawh122';
export var videoReviewsStyledAppContainer = _7a468({defaultClassName:'_1fawh125',variantClassNames:{},defaultVariants:{},compoundVariants:[]});
export var videoReviewsVideoContainerUIStyle = '_1fawh121';
export var videoReviewsVideoStyle = '_1fawh126';