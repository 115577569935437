import "../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyles%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA23S227CMAwA0He%2BwlKeQbk5Tc0Tleh%2F9JLuBoNdgU3798mdJjlR46cepXbsZHM6nG6Xs4bvFcB6%2FfdlCJSNNjqzFWoJ1DQvqY5ADYlDqidQ%2B2bftHupSKB84xu0UgPnrYOpeqkVgWqbaGqUGrmaHvSQaU2g9LykdpzXT3HK8vYEytWu85XUgdU79FnHI4GqXOxrLzXxdIw1tpY6LU7njjVxSL3n3nbtrm2lPizO4XFxDk9zNeyrrIsDgRoHDqlHApUwYcpO9swZkEPq6V87qWeeehjCmE3nhUDhiClk1V65WsCuyva%2BzZPsEEep74tz%2BOC7cM757DY%2FF9%2FOZfGdXQn0%2BSrlRhBz%2BSIwNiejCUwozBDYIpmxBNYX5ghsUcJ4Al%2F%2BiwRY1g0ERpcbqxkzinyWnGqCWJbtuLVQHrAn0BuXjtvVzy9J5bov9QMAAA%3D%3D%22%7D"
import "../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2Fshared%2FNewQuote%2FnewQuote.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA9VX227jIBB9z1cgrVZqH6h8S5zSl1V%2FZOUY4tA44GJSJ13l31fG2AGCm6jdpNonCxjPnDlzYXj4HZZLvH57D8CfCQCY1lWZ7RFYlmT3NAHqCzEVJJeUMwQEb9rtl20t6XIPc84kYRKBnDBJRHuUlbRgkEqyqc3tRZavC8G3DMOcl1wg8JaJOwh5yfdN1dw%2FTQ6Thx5MqMBsMlFQhkAAsq3kT2pnBxuK5QqBMAxmlYJ4gvkEXF1lOYELIhtC2AcYi6xCYB60ag0wkc0M44xY57E6X3CBiYAiw3RbIxAGwU8vuEsJvRSztqtZCfxUNysqSXtUZRhTViAQJo6XyVXjbxiaXmYo5%2BV2wz6Xa32K6BiosIZRly1HBmYOA7P%2FN44A5FtRtxIVpyeMp18LrZKrZSbkCXx1RBgeJd3leH4DKIa5xys3NeWkW0mZMqoj9iOO4zhR4VxyJmFN3wkC4bSLmtpqCC1WEoEkUGIlZQSu9F6YOsoX55Unc4%2FyuUf5VDdQo6vOI51PkuwkpAwrHuBJHHMThtnIqd3IsZJzUuNf1ZJhh3zr7dU6PXjZM9iz3K%2BdxuJmzfIWqdo1QcNqca1mbFdqd7E6tldX9ljHI32049GtDRz0k%2FWa%2Bup1fqakFFYESrKUFogXC0TynDxPo4ubhqFn%2FS1pVN4wjdzC2SjbBk8931Zg%2Bk1fBEcruhtAodBUO4aZa3g8S4y%2F%2BNhfI7H%2F6HaIXEiVMTfDBZeSbxCAsSP1epvC6%2B%2BhHm2iG8CvDcE0A3dGjaSztNrdK1jO2D14I3mFQBR0Kj3oR%2FFfMpONumLNMe1yGLwCoN07mKCnGvQg5hOaaSHnSeFIpVrq2Dt9Th5L6sPKOTtLWaYX2rQ9BBwxnNaaf9pw0jWOPGTgr4bZZOCMk2cost4JFsiVBqnTdZr2%2BIZRKvX8tbaTofOt1z8MBJJXw5vWc1TLfUkQqHlJsXXoPgks06824CRwAfc7h8nhL4jESgsDEAAA%22%7D"
export var accentColor = 'var(--oloywp6)';
export var iconTrustPilotStyle = '_1lfdkvzp';
export var newQuoteAuthorBlock = '_1lfdkvzf';
export var newQuoteAuthorImgWrapperBlock = '_1lfdkvzh';
export var newQuoteAuthorNameStyle = '_1lfdkvzi';
export var newQuoteAuthorPositionStyle = '_1lfdkvzj';
export var newQuoteAuthorTextBlock = '_1lfdkvzg';
export var newQuoteContainerStyle = '_1lfdkvz0';
export var newQuoteHighlightedTextStyle = '_1lfdkvzc';
export var newQuoteMobileSliderNavStyle = '_1lfdkvz2';
export var newQuoteSlideDetailsStyle = '_1lfdkvzd';
export var newQuoteSlideQuoteTextStyle = '_1lfdkvzb';
export var newQuoteSlideStyle = '_1lfdkvz5';
export var newQuoteSlideTrustPilotStTextStyle = '_1lfdkvza';
export var newQuoteSlideTrustPilotStarsStyle = '_1lfdkvz9';
export var newQuoteSlideTrustPilotStyle = '_1lfdkvz7';
export var newQuoteSlideTrustPilotTextStyle = '_1lfdkvz8';
export var newQuoteSliderContainerStyle = '_1lfdkvz4';
export var newQuoteSliderMobileNavButtonsStyle = '_1lfdkvz3';
export var newQuoteSliderNavButtonsStyle = '_1lfdkvz6';
export var newQuoteSliderWrapperStyle = '_1lfdkvz1';
export var newQuoteTrafficBlock = '_1lfdkvzk';
export var newQuoteTrafficImgWrapperBlock = '_1lfdkvzq';
export var newQuoteTrafficTextBlock = '_1lfdkvzl';
export var newQuoteTrafficTextCapacityStyle = '_1lfdkvzm';
export var newQuoteTrafficTextSinceStyle = '_1lfdkvzo';
export var newQuoteTrafficTextTitleStyle = '_1lfdkvzn';
export var starRatingContainerStyle = '_1lfdkvze';